import React, { useEffect } from 'react';
import Router from 'next/router';
import Authentication from 'containers/Authentication/Authentication';
import useAuth from 'contexts/auth/auth.context';
import { getCurrentUser } from 'helper/user';
import { HOME_ROUTE } from 'constants/navigation';
import { SEO } from 'components/SEO/SEO';
import Mixpanel from 'helper/Mixpanel';

const Login: React.FC = () => {
  const { authState, authDispatch } = useAuth();
  const { user } = authState;

  useEffect(() => {
    if (user && getCurrentUser()) Router.push(HOME_ROUTE);
    authDispatch({ type: 'SET_FORM', payload: 'SIGNIN' });
  }, []);

  useEffect(() => Mixpanel.track('login'), []);

  if (user) return <></>;

  return (
    <>
      <SEO title="Login | Devo Partner Centre" />
      <Authentication />
    </>
  );
}

export default Login;
